/* DeleteAccount.css */

.delete-account-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.language-selector .ant-btn-default {
    margin: 10px 5px 20px;
}

/* Style labels */
.ant-form-vertical .ant-form-item-label {
    text-align: left;
}

/* Style form items (inputs) */
.ant-form-vertical .ant-form-item-control-input {
    width: 100%;
    /* Adjust the width as needed */
}
@import '~antd/dist/antd.css';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-weight: bold;
  font-style: normal;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin: 1.5em;
}
